import Injectable from '@/modules/shared/domain/di/injectable';

@Injectable()
export default class LoadCaptchaScriptService {
  load(): void {
    if (!document.querySelector('[src="https://www.google.com/recaptcha/api.js"]')) {
      const captcha_script = document.createElement('script');
      const key = process.env.VUE_APP_RECAPTCHA || '';
      captcha_script.setAttribute('src', `https://www.google.com/recaptcha/api.js?render=${key}`);
      captcha_script.async = true;
      captcha_script.defer = true;
      document.head.appendChild(captcha_script);
    }
  }
}
