import TYPES from '@/modules/shared/types';

// Domain
import { ForgotPasswordRepository } from '../../../domain/repositories';
import { ForgotPassword } from '../../../domain/dtos';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
export default class ForgotPasswordHttpRepository implements ForgotPasswordRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly path = `${process.env.VUE_APP_API_URL}/api/v1/app/auth/recovery-password/email/`;

  restore(forgot_password: ForgotPassword): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = {
        'X-CAPTCHA': forgot_password.captcha,
        'Content-Type': 'application/json',
      };
      const body = {
        email_address: forgot_password.email_address,
      };
      this.http_connector.execute(this.path, 'POST', body, 0, false, headers)
        .then((response) => {
          response.json().then(({
            data,
            message,
            success,
          }) => {
            if (success) {
              resolve(data);
            } else {
              reject(new Error(message));
            }
          });
        })
        .catch(reject);
    });
  }
}
