import fixtures from './fixtures.json';
import Injectable from '@/modules/shared/domain/di/injectable';
import { RecaptchaRepository } from '../../../domain/repository/recaptcha-repository';
import { RecaptchaEntity } from '../../../domain/entities/recaptcha-entity';

@Injectable()
export default class RecaptchaDummyRepository implements RecaptchaRepository {
  getCaptcha(): Promise<RecaptchaEntity> {
    return Promise.resolve(fixtures);
  }
}
