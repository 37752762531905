import TYPES from '@/modules/shared/types';

// Domain
import { CreateOnBoardingInviteFriendRepository }
  from '@/modules/home-dashboard/invite-friend/domain/repositories/create-on-boarding-invite-friend-repository';
import { InviteFriendDto } from '@/modules/home-dashboard/invite-friend/domain/dtos/invite-friend-dto';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';

@Injectable()
class CreateOnBoardingInviteFriendHttpRepository implements CreateOnBoardingInviteFriendRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly endpoint = `${process.env.VUE_APP_API_URL}/api/v1/app/dashboard/onboarding-invitation/`;

  create(invite_friend_dto: InviteFriendDto): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = {
        'X-CAPTCHA': invite_friend_dto.captcha,
        Authorization: `Bearer ${sessionStorage.getItem('authorization')}` || '',
        'Content-Type': 'application/json',
      };
      const body = {
        referred_email: invite_friend_dto.referred_email,
      };
      this.http_connector.execute(this.endpoint, 'POST', body, 0, false, headers)
        .then((response) => {
          response.json()
            .then(({ success, data, message }) => {
              if (success) {
                resolve(data);
              } else {
                reject(new Error(message));
              }
            });
        })
        .catch(reject);
    });
  }
}

export default CreateOnBoardingInviteFriendHttpRepository;
