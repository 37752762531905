import TYPES from '@/modules/shared/types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import HttpConnector from '@/modules/shared/domain/connectors/http';
import { RecaptchaRepository } from '../../domain/repository/recaptcha-repository';
import { RecaptchaEntity } from '../../domain/entities/recaptcha-entity';

@Injectable()
export default class RecaptchaHttpRepository implements RecaptchaRepository {
  @Inject(TYPES.HTTP_CONNECTOR)
  private readonly http_connector!: HttpConnector;

  private readonly key = process.env.VUE_APP_RECAPTCHA || '';

  getCaptcha(): Promise<RecaptchaEntity> {
    return new Promise((resolve) => {
      grecaptcha.ready(() => {
        grecaptcha.execute(this.key, { action: 'submit' })
          .then((token: string) => resolve({ token }));
      });
    });
  }
}
