import { VueConstructor } from 'vue';
import VueTheMask from 'vue-the-mask';
import money from '@titou10/v-money';
import Notifications from 'vue-notification';
import VueApexCharts from 'vue-apexcharts';
import { Plugin } from 'vue-fragment';
import TYPES from '../types';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';

// Application
import { LoadCaptchaScriptService } from '@/modules/recaptcha/application/services';

// Domain
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';

@Injectable()
export default class Application {
  @Inject(TYPES.VUE)
  private readonly vue!: VueConstructor;

  @Inject(TYPES.LOAD_SCRIPT_CAPTCHA)
  private readonly load_captcha_script!: LoadCaptchaScriptService;

  create(app: VueConstructor) {
    this.vue.config.productionTip = false;
    this.vue.use(VueTheMask);
    this.vue.use(money);
    this.vue.use(Notifications);
    this.vue.use(VueApexCharts);
    this.vue.component('apexchart', VueApexCharts);
    this.vue.use(Plugin);

    this.load_captcha_script.load();

    // eslint-disable-next-line new-cap
    return new this.vue({
      router,
      store,
      vuetify,
      render: (create) => create(app),
      i18n,
    }).$mount('#app');
  }
}
