import { Container } from 'inversify-props';
import TYPES from './types';

// Infrastructure
import RecaptchaDummyRepository from './infrastructure/repositories/recaptcha-dummy-repository';
import RecaptchaHttpRepository from './infrastructure/repositories/recaptcha-http-repository';

// Application
import GetRecaptchaQuery from './application/queries/get-recaptcha-query';
import LoadCaptchaScript from './application/services/load-script-captcha-service';

// Domain
import { RecaptchaRepository } from './domain/repository/recaptcha-repository';

export default function setupRecaptchaModule(container: Container) {
  const withDummies = process.env.VUE_APP_REPOSITORY_MODE === 'dummy';
  container
    .bind<RecaptchaRepository>(TYPES.RECAPTCHA_REPOSITORY)
    .to(
      withDummies
        ? RecaptchaDummyRepository
        : RecaptchaHttpRepository,
    ).inSingletonScope();

  container
    .bind<GetRecaptchaQuery>(TYPES.GET_RECAPTCHA_QUERY)
    .to(GetRecaptchaQuery)
    .inSingletonScope();

  container
    .bind<LoadCaptchaScript>(TYPES.LOAD_SCRIPT_CAPTCHA)
    .to(LoadCaptchaScript)
    .inSingletonScope();
}
