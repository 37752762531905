import TYPES from '../../types';
import Injectable from '@/modules/shared/domain/di/injectable';
import Inject from '@/modules/shared/domain/di/inject';
import Query from '@/modules/shared/domain/use-cases/query';
import { RecaptchaEntity } from '../../domain/entities/recaptcha-entity';
import { RecaptchaRepository } from '../../domain/repository/recaptcha-repository';

@Injectable()
export default class GetRecaptchaQuery
  extends Query<Promise<RecaptchaEntity>> {
  @Inject(TYPES.RECAPTCHA_REPOSITORY)
  private readonly repository!: RecaptchaRepository;

  internalExecute(): Promise<RecaptchaEntity> {
    return this.repository.getCaptcha();
  }
}
